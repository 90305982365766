import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout isHome={true}>
    <Seo title="Accueil" />
    <section className="mb-5 pb-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="row d-flex flex-row align-items-end shift-top">
            <div className="col-4">
              <img
                className="img-fluid w-50"
                src="/img/Base_500ml.png"
                alt="Base standard"
              />
              <h2 className="h3">Base standard</h2>
            </div>
            <div className="col-4">
              <img
                className="img-fluid w-50"
                src="/img/RushSalt_100ml.png"
                alt="Base standard"
              />
              <h2 className="h3">Sels de nicotine</h2>
            </div>
            <div className="col-4">
              <img
                className="img-fluid w-50"
                src="/img/VelvetSalt_Mini.png"
                alt="Base standard"
              />
              <h2 className="h3">Booster</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p>
                30VG/70PG
                <br />
                500ml
              </p>
            </div>
            <div className="col-4">
              <p>
                Rush Salt
                <br />
                100ml
              </p>
            </div>
            <div className="col-4">
              <p>Velvet Salt</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Link className="btn btn-primary d-block" to="/produits/">
                Voir tous nos produits
              </Link>
            </div>
            <div className="col-6">
              <Link className="btn btn-primary d-block" to="/acces-pro/">
                Accès pour les professionnels
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-5">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          title="Vapor flavor - EVERYWHERE IN SWITZERLAND
"
          className="embed-responsive-item"
          src="https://www.youtube-nocookie.com/embed/ufb4UWSEl_I?rel=0&loop=1&autoplay=1&mute=1&fs=0&modestbranding=1"
        ></iframe>
      </div>
    </section>
    <section>
      <div className="row">
        <div className="col-md-12 py-5 text-center mb-5">
          <h2 className="mb-4">Où trouver nos produits?</h2>
          <a href="/distributeurs" className="btn btn-primary">
            Voir tous les distributeurs
          </a>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
